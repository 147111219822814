.Container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  margin-top: 25px;
}
.Container h1 {
  color: var(--bo-black);
  font-weight: 700;
  font-size: var(--bo-heading-h1-5);
}

.Container p {
  font-size: var(--bo-font-size-regular-2);
}

.CloudCardCont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
}

.Container p {
  text-align: left;
}

.CloudCard {
  background-color: white;
  border: 1px solid var(--bo-border);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  width: 316px;
  height: 320px;
  margin: 10px;
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;
  transform: translateY(-20px);
  cursor: pointer;
}
.CloudCard:hover {
  background-color: white;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  width: 316px;
  height: 320px;
  margin: 10px;
  transition: transform 0.2s;
  cursor: pointer;
}

.icon {
  width: 0px;
  height: 0px;
  /* font-size: 30px; */
}
.titleCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.titleCont div {
  text-align: left;
}
.titleCont p {
  font-size: 14px;
  text-align: left;
}

@media (max-width:768px) {
  .Container h1{
    font-size: 25px;
  }
}

@media (max-width:790px) {
  .CloudCard {
    height: 360px;
    margin-bottom: 10px;  
  } 
}
