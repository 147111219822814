.Container {
  width: 27%;
  position: absolute;
  right: 0px;
  margin-right: 50px;
  margin-top: 20px;
  background-color: var(--bo-light);
}

.Heading {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 50px;
  font-size: var(--bo-heading-h4-1);
  border-bottom: 1px solid gray;
}

.Price {
  color: black;
  padding: 20px;
}

.Details {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.gap {
  height: 20px;
  background-color: white;
}

.Discount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.removecoupon {
  color: rgb(67, 124, 148);
  font-style: italic;
  font-size: var(--bo-font-size-regular-2);
}
.removecoupon:hover {
  text-decoration: underline;
  color: rgb(67, 124, 148);
  font-style: italic;
  font-size: var(--bo-font-size-regular-2);
}

.Checkoutbtn {
  display: flex;
  justify-content: center;
  width: 84%;
  border-radius: 0px;
}

.Paypal {
  height: 300px;
  overflow: auto;
}

.Paypalbtn {
  background: #fff;
}
