.aboutUsContainer {
    padding: 105px 150px 0 150px;
    display: flex;
    align-items: center;
    gap:10px;
}

.pageTitle {
    color: var(--bo-black);
    font-size: var(--bo-heading-h1-4) !important;
    font-weight: 700;
    /* margin-bottom: 30px; */
    text-align: left;
    
}

