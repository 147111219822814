/* src/StarRating.css */
.stars {
  font-size: 20px;
  position: relative;
  display: inline-block;
  color: #ccc; /* Grey color for empty stars */
}

.starsOuter {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.starsInner {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  color: #6534e4; /* Gold color for filled stars */
}
