.bannerWrapper {
    text-align: center;
    padding-top: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 90%;
    margin-left: 100px;
    margin-right: 100%;
  }
  .anotherPoints {
    margin-left: 100px;
  
  }
  
  .pointList2 {
    display : flex;
    /* grid-template-columns: auto; */
    flex-wrap: wrap;
  }
  
  .LeftCont {
    width: 60%;
    text-align: start;
  } 
  
  .Container h1 {
    color: var(--bo-black);
    font-weight: 700;
    font-size: var(--bo-heading-h1-4);
  }
  
  
  .Container h4 {
    text-align: left;
    font-size: 16px;
  }
  
  .Section2 {
    flex : 0 0 50%;
  }
  
  .Section {
    text-align: left;
  }
  .Section h4, .Section2 h4{
    font-size: 18px;
    font-weight: 600;
  }
  
  .Section2 ul {
    width: 90%;
  }
  
  
  .Section2 ul, .Section ul {
    margin-left: 18px;
  }
  
  .afterBannerContent {
    margin: 0px 100px;
  
  }