.courseCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 410px;
  height: 530px;
  background-color: #fff;
}

.wishlist {
  position: relative;
  font-size: 14px;
  z-index: 2;
  top: 15px;
  left: 360px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a5bca;
}

.wishlist:hover {
  position: relative;
  font-size: 14px;
  z-index: 2;
  top: 15px;
  left: 360px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #95a0c5;
}
.AvatarContainer {
  width: 36px;
  height: 36px;
}

.Avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.imageContainer {
  width: 100%;
  height: 42.5%;
  position: relative;
}

.ProfileBody {
  font-size: 14px;
  display: flex;
  gap: 20px;
}

.Profile {
  display: flex;
  align-items: center;
  gap: 5px;
}

.duration {
  display: flex;
  align-items: center;
  gap: 5px;
}

.courseImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courseDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42.5%;
  border-bottom: 1px solid #ddd;
  padding: 20px;
}

.courseTitle {
  font-size: var(--bo-heading-h4-2);
  color: black;
  font-weight: 700;
  margin-bottom: 5px;
}

.additionalInfo {
  display: flex;
  align-items: center;
  gap: 15px;

  margin-bottom: 5px;
  font-size: 0.875em;
  color: #555;
}

.footer {
  display: flex;
  padding: 16px 20px;
  height: 15%;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: var(--bo-font-size-small);
  font-weight: bold;
}

.viewCartButton {
  background-color: white;
  font-size: var(--bo-font-size-small);
  font-weight: 100;
  height: 24px;
  color: #3a5bca;
  border: 1px solid #3a5bca;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.viewCartButton:hover {
  background-color: #3a5bca;
  font-size: var(--bo-font-size-small);
  font-weight: 100;
  height: 24px;
  color: white;
  border: 1px solid white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.Buttons {
  display: flex;
  gap: 20px;
}
