.Container {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}
.Container h1 {
  color: var(--bo-black);
  font-weight: 700;
  font-size: var(--bo-heading-h1-2);
}

.TecCardCont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  gap: 30px;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 10px;
  width: 260px !important;
  height: 300px !important;
  margin: 10px;
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;
  transform: translateY(-20px);
  cursor: pointer;
}

.icon {
  width: 0px;
  height: 0px;
  /* font-size: 40px; */
}
.titleCont {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.titleCont div {
  text-align: center;
}
.titleCont p {
  font-size: 13px;
  text-align: center;
}

.Image {
  width: 120px;
}
