.container {
  padding-top: 100px;
}

.containerHeader {
  margin: auto;
  text-align: center;
}

.containerHeader h1 {
  font-weight: 700;
  color: var(--bo-black);
  font-size: var(--bo-heading-h1-5);
}
