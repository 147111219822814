.Container {
    text-align: center;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    margin-left: 100px;
    margin-right: 100px;
  }

  .Contact {
    display: flex;
    gap: 50px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .newsletterCont {
    text-align: start;
    /* width: 90%; */
  }

  .newsletter {
    width: 50%;
  }

  .Buttons {
    display: flex;
  } 

  .DetailsCont {
    display: flex;
    width: 150%;
    gap: 30px;
  }

  .Details {
    width: 80%;
  }


  .address {
    width: 80%;
  }