.marqueeContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  flex: nowrap;
}

.marquee {
  display: flex;
  animation: marquee 30s linear infinite;
}

.marqueeReverse {
  animation: marqueeReverse 30s linear infinite;
}

.marqueeImg {
  width: 200px;
  height: 50px;
  margin-right: 50px; 
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes marqueeReverse {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width:768px) {
  .marquee {
   
    animation: marquee 10s linear infinite;
  }
  
  .marqueeReverse {
    animation: marqueeReverse 10s linear infinite;
  }
  
}