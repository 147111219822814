.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Container h1 {
    color: var(--bo-dark);
    font-size: var(--bo-heading-h1-5);
    font-weight: 700;
  }

  .cardcontainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
  }