.Container {
  width: 100vw;
  overflow-x: hidden;
  padding: 0px;
  padding-top: 100px;
}

.TopContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInWave {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  /* 50% {
    opacity: 0.5;
    transform: translateY(10px);
  } */
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.TopContent {
  background-image: url("../../assets/img/business\ optima\ background\ \(1\).jpg");
  background-size: cover;
  background-position: 0px -10px;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeInWave 1s ease-out;
  padding-top: 50px;

}

.rocket {
  position: relative;
  top: -350px;
  left: 520px;
}

.TextCont {
  width: 60%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}

.TextCont h1 {
  width: 100%;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 30px;
}

/* .TextCont h1, .TextCont p {
  animation: fadeInWave 1s ease-out;
}

.TextCont h1 {
  animation-delay: 0.2s;
}

.TextCont p {
  animation-delay: 0.4s;
} */

.TextCont p {
  width: 100%;
  font-size: 18px;
}

.TopLeft {
  width: 60%;
}

.TopLeft h1 {
  color: var(--bo-dark);
  font-size: var(--bo-heading-h1-5);
  font-weight: 700;
  margin-bottom: 40px;
}

.TopLeft p {
  font-size: var(--bo-font-size-large-1);
  color: var(--bo-black);
  font-weight: 500;
  margin-bottom: 40px;
}

.Image {
  width: 37%;
  background-image: cover;
  margin-top: 100px;
}

.AboutImageBottom {
  margin-top: 5%;
  margin-right: 3%;
}

.AboutImageTop {
  display: none;
}

.Image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.Service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Serviceright {
  width: 65%;
}

.Serviceright p {
  font-size: var(--bo-font-size-large-1);
  color: var(--bo-black);
  font-weight: 500;
  margin-bottom: 20px;
}

.Image1 {
  width: 32%;
  background-image: cover;
}

.Image1 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ConsultingContainer {
  display: flex;
  gap: 20px;
}

.ConsultingContainer p {
  font-size: var(--bo-font-size-large-1);
  color: var(--bo-black);
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
}

.ConsultingContainer h1 {
  font-weight: 700;
  color: var(--bo-black);
  font-size: var(--bo-heading-h1-5) !important;
  margin-bottom: 30px;
  /* margin-bottom: 20px; */
  text-align: left;
}

.ConsultingLeft {
  text-align: center;
  margin-right: 100px;
  margin-left: 150px;
}

.ConsultingForm {
  background-image: url("../../assets/img/Vector\ 16.png");
  padding-right: 50px;
  padding-bottom: 30px;
}

.TransformContainer {
  display: flex;
  gap: 40px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--bo-button);
  color: var(--bo-light);
}

.Transformright h1 {
  font-size: var(--bo-heading-h1-3);
  font-weight: 700;
  margin-bottom: 40px;
}

.Transformright {
  font-size: var(--bo-font-size-regular-2);
}

.Image3 {
  width: 300px;
  height: 300px;
}

.button {
  all: unset;
  background-color: var(--bo-button);
  color: white;
  border: 1px solid var(--bo-light);
  font-size: 18px !important;
  padding: 5px 12px !important;
  cursor: pointer;
  font-weight: 700;
  transition: 0.3s ease-in-out;
}

.button:hover {
  all: unset;
  background-color: white;
  font-size: 18px !important;
  color: var(--bo-button);
  border: 1px solid var(--bo-button);
  padding: 5px 12px;
  padding: 5px 12px !important;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.Card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  width: 400px;
  height: 400px;
  margin: 10px;
  transition: transform 0.2s;
  cursor: pointer;
}

.ButtonCont {
  display: flex;
  gap: 20px;
}

.ClientContainer {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 575.98px) {

  .TopLeft,
  .Serviceright,
  .Image,
  .Image1,
  .ConsultingLeft {
    width: 100%;
    margin-top: 10px;
  }

  .TopContent,
  .Service,
  .ConsultingContainer,
  .TransformContainer {
    flex-direction: column;
    align-items: center;
  }

  .TopLeft h1,
  .ConsultingContainer h1,
  .Transformright h1 {
    font-size: var(--bo-heading-h1-1);
  }

  .TopLeft p,
  .Serviceright p,
  .ConsultingContainer p {
    font-size: var(--bo-font-size-medium-2);
  }

  .Image,
  .Image1 {
    margin-bottom: 20px;
  }

  .button {
    font-size: var(--bo-font-size-medium-2);
    padding: 10px 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  .TopLeft,
  .Serviceright,
  .Image,
  .Image1,
  .ConsultingLeft {
    width: 100%;
    margin-top: 10px;
  }

  .TopContent,
  .Service,
  .ConsultingContainer,
  .TransformContainer {
    flex-direction: column;
    align-items: center;
  }

  .TopLeft h1,
  .ConsultingContainer h1,
  .Transformright h1 {
    font-size: var(--bo-heading-h1-2);
  }

  .TopLeft p,
  .Serviceright p,
  .ConsultingContainer p {
    font-size: var(--bo-font-size-medium-2);
  }

  .Image,
  .Image1 {
    margin-bottom: 20px;
  }

  .button {
    font-size: var(--bo-font-size-medium-2);
    padding: 10px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {

  .TopLeft,
  .Serviceright,
  .Image,
  .Image1,
  .ConsultingLeft {
    width: 100%;
    margin-top: 10px;
  }

  .TopContent,
  .Service,
  .ConsultingContainer,
  .TransformContainer {
    flex-direction: column;
    align-items: center;
  }

  .TopLeft h1,
  .ConsultingContainer h1,
  .Transformright h1 {
    font-size: var(--bo-heading-h1-3);
  }

  .TopLeft p,
  .Serviceright p,
  .ConsultingContainer p {
    font-size: var(--bo-font-size-large-2);
  }

  .Image,
  .Image1 {
    margin-bottom: 20px;
  }

  .button {
    font-size: var(--bo-font-size-medium-2);
    padding: 10px 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

  .TopLeft,
  .Serviceright,
  .Image,
  .Image1,
  .ConsultingLeft {
    width: 100%;
    margin-top: 10px;
  }

  .TopContent,
  .Service,
  .ConsultingContainer,
  .TransformContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  .TopLeft h1,
  .ConsultingContainer h1,
  .Transformright h1 {
    font-size: var(--bo-heading-h1-4);
  }

  .TopLeft p,
  .Serviceright p,
  .ConsultingContainer p {
    font-size: var(--bo-font-size-large-2);
  }

  .Image,
  .Image1 {
    margin-bottom: 0;
  }

  .button {
    font-size: var(--bo-font-size-large-2);
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) {

  .TopContent,
  .Service,
  .ConsultingContainer,
  .TransformContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  .TopLeft h1,
  .ConsultingContainer h1,
  .Transformright h1 {
    font-size: var(--bo-heading-h1-5);
  }

  .TopLeft p,
  .Serviceright p,
  .ConsultingContainer p {
    font-size: var(--bo-font-size-large-1);
  }

  .Image,
  .Image1 {
    margin-bottom: 0;
  }

  .button {
    font-size: var(--bo-font-size-large-2);
    padding: 10px 20px;
  }
}



@media (max-width:768px) {

  .ConsultingContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
  }


  .ConsultingLeft h1 {
    margin: 0 auto;
    width: fit-content;
    font-size: 30px !important;
    font-weight: 900 !important;
    margin-bottom: 10px;
  }

  .ConsultingLeft {
    width: 100vw;
    padding: 10px;
    margin: 0;
    overflow-x: hidden;

  }

  .AboutImageBottom {
    display: none;
  }

  .AboutImageTop {
    display: inline;
    width: 300px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  .TopContainer {
    margin: 0;
    padding: 0;
  }

  .TopContent {
    margin: 0;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    background-position: 0px 100px;
    width: 100vw;
    height: 450px;
    background-size: 800px 400px; 
  }

  .TextCont {
    width: 90%;
  }

  .TextCont h1 {
    font-size: 30px;
    font-weight: 700 !important;
  }

  .ButtonCont{
    flex-direction: row;
    justify-content: center;
    gap:5px;
  }

  .button{
    font-size: 10px !important; 
    padding: 10px !important;
  }

  .ConsultingForm {
  display: flex;
  justify-content: center !important;
  align-items: center;
  }


}

@media (max-width:440px) {
  .TopContent {
    margin: 0;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    background-position: 0px 180px;
    width: 100vw;
    height: 400px;
    background-size: 460px 230px; 
  }
}

@media (max-width:385px) {
  .TopContent {
    margin: 0;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    background-position: 0px 230px;
    width: 100vw;
    height: 400px;
    background-size: 400px 200px; 
  }
}