
.footer {
  background-color: #f7f7f7;
  padding: 40px 0;
  border-top: 1px solid #ddd;
  margin-top: 40px;
  z-index: 1000;
  padding-left: 50px;  
  padding-right: 50px; 
  box-sizing: border-box;
}

.container {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
}


.column {
  flex-basis: 25%;
  margin-bottom: 20px;
  text-align: left;
}


.column h5 {
  margin-top: 0;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}


.column ul {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}


.column li {
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
}

.column a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.column a:hover {
  color: #333;
}


.socialIcon {
  font-size: 18px;
  margin-right: 10px;
  color: #666;
}

.socialIcon:hover {
  color: #333;
}


.copyright {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin-top: 20px;
}


img.appLogo {
  width: 200px;
  max-width: 100%;
}


.logoContainer {
  text-align: center !important;
}


.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.socialIcons i {
  font-size: 24px;
  color: #333;
}


@media (max-width: 768px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .column {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .column h5 {
    font-size: 18px;
  }

  .column a {
    font-size: 16px;
  }

  .socialIcons {
    gap: 15px;
  }

  .socialIcons i {
    font-size: 28px;
  }

  .copyright {
    font-size: 16px;
    margin-top: 30px;
  }

  img.appLogo {
    width: 150px; 
  }
}


@media (max-width: 480px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .column {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .column h5 {
    font-size: 16px;
  }

  .column a {
    font-size: 14px;
  }

  .socialIcons {
    gap: 15px;
  }

  .socialIcons i {
    font-size: 30px;
  }

  .copyright {
    font-size: 14px;
    margin-top: 20px;
  }

  img.appLogo {
    width: 120px;
  }
}
