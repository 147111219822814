.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets//img/Rectangle\ 2498\ \(1\).png");
}

.Container h1 {
  color: var(--bo-light);
  font-size: var(--bo-heading-h2-2);
  font-weight: 700;
}

.cardcontainer {
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 100px 100px 100px;
  background-image: url("../../../assets/img/Group\ 1321313951\ \(1\).png");
  background-size: cover;
  background-repeat: no-repeat;
}

.MainImage {
  height: 30%;
  width: 30%;

}

.vector1 {
  position: relative;
  top: 20px;
  right: -50%;
  height: 15%;
  width: 60%;
}

.Image1 {
  position: relative;
  top: -11.5%;
  right: -48%;
  height: 25%;
  width: 40%;
}

.vector2 {
  position: relative;
  top: -5%;
  right: -4%;
  height: 15%;
  width: 100%;
}

.Image2 {
  position: relative;
  top: -20.5%;
  right: -2%;
  height: 25%;
  width: 50%;
}

.vector3 {
  position: relative;
  top: -10%;
  right: -50%;
  height: 15%;
  width: 60%;
}

.Image3 {
  position: relative;
  top: -25.5%;
  right: -48%;
  height: 25%;
  width: 40%;
}

.vector4 {
  position: relative;
  top: 5%;
  left: -10%;
  height: 15%;
  width: 60%;
}

.Image4 {
  position: relative;
  top: -10%;
  right: -12%;
  height: 25%;
  width: 40%;
}

.vector5 {
  position: relative;
  top: -0%;
  left: -15%;
  height: 15%;
  width: 100%;
}

.Image5 {
  position: relative;
  top: -15.5%;
  left: 37%;
  height: 25%;
  width: 50%;
}

.vector6 {
  position: relative;
  top: -5%;
  left: -20%;
  height: 15%;
  width: 60%;
}

.Image6 {
  position: relative;
  top: -20.5%;
  left: 3%;
  height: 25%;
  width: 40%;
}

.vectorCont {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

.vectorContRight {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


/* Keyframes for Vector Animations */
@keyframes revealFromLeft {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes revealFromRight {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for Image Reveal */
@keyframes revealFromTop {
  0% {
    height: 0;
    overflow: hidden;
  }

  100% {
    height: 100%;
    overflow: visible;
  }
}

/* Vector Animations */
.vector1,
.vector2,
.vector3 {
  animation: revealFromLeft 1s ease-out forwards;
}

.vector1 {
  animation-delay: 0s;
}

.vector2 {
  animation-delay: 0s;
}

.vector3 {
  animation-delay: 0s;
}

.vector4,
.vector5,
.vector6 {
  animation: revealFromRight 1s ease-out forwards;
}

.vector4 {
  animation-delay: 0s;
}

.vector5 {
  animation-delay: 0s;
}

.vector6 {
  animation-delay: 0s;
}


@keyframes revealFromTop {
  0% {
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }

  100% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

.Image1,
.Image2,
.Image3,
.Image4,
.Image5,
.Image6 {
  animation: revealFromTop 1s ease-out forwards;
}

.Image1 {
  animation-delay: 1s;
}

.Image2 {
  animation-delay: 1s;
}

.Image3 {
  animation-delay: 1s;
}

.Image4 {
  animation-delay: 1s;
}

.Image5 {
  animation-delay: 1s;
}

.Image6 {
  animation-delay: 1s;
}


@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  30% {
    opacity: 0.5;
  }

  40% {
    opacity: 0;
  }

  70% {
    opacity: 0.7;
  }

  20% {
    opacity: 0;
  }

  10% {
    opacity: 0.4;
  }

  50% {
    opacity: 0;
  }
}

/* MainImage with Blinking Effect */
.MainImage img {
  animation: blink 2s;
  /* Adjust duration as needed */
}


.visible .MainImage img {
  animation: blink 2s;
}

/* Vector Animations */
@keyframes revealFromLeft {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes revealFromRight {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for Image Reveal */
@keyframes revealFromTop {
  0% {
    clip-path: inset(0 0 100% 0);
    opacity: 0;
  }

  100% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

/* Apply Animations when visible */
.animateVector:nth-of-type(n) {
  animation: revealFromLeft 1s ease-out forwards;
}

.animateVector:nth-of-type(n) {
  animation: revealFromRight 1s ease-out forwards;
}

.animateImage :nth-of-type(n) {
  animation: revealFromTop 1s ease-out forwards;
}

.animateMainImage {
  animation: blink 1s;
}

@media (max-width:440px) {
  .Container {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets//img/Rectangle\ 2498\ \(1\).png");
    background-size: contain;
  }
  .Container h1{
   margin-top: 100px;
  }

  .cardcontainer {
    height: 500px;

  }

  .MainImage {
    height: 200px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .MainImage img {
    height: 200px;
    width: 120px;

  }
}