.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 450px;
  /* background-color: #f9f9f9; */
}

.Container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 15px;
  border-top: 3px solid black;
  background-color: white;
  box-shadow: 0px 0px 4px 6px #fff6e3 !important;
  -webkit-box-shadow: 0px 0px 4px 6px #fff6e3 !important;
  -moz-box-shadow: 0px 0px 4px 6px #fff6e3 !important;
  box-shadow: 0px 4px 6px #fff6e3;
}

.Container input,
select {
  width: 100%;
}

.Container textarea {
  height: 100px;
}

.Container input,
select,
textarea {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-size: 16px;
}

.Form {
  display: flex;
  gap: 10px;
}



/* Responsive Design */
@media (max-width: 768px) {
  .Container {
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }

  .Container form {
    padding: 15px;
  }

  .Container input,
  select,
  textarea {
    font-size: 14px;
  }

  .Container Button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .Container {
    padding: 5px;
    width: 100%;
    max-width: 100%;
    margin-left: 10%;
  }

  .Container form {
    padding: 10px;
  }

  .Container input,
  select,
  textarea {
    font-size: 14px;
  }

  .Container Button {
    font-size: 14px;
    padding: 10px;
  }
}