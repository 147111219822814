.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
  }
  
  .Container h1 {
    color: var(--bo-dark);
    font-size: var(--bo-heading-h1-1);
    font-weight: 700;
  }

  .cardcontainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    padding-bottom: 40px;
  }

  @keyframes fadeInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-20px); 
    }
    100% {
      opacity: 1;
      transform: translateY(0); 
    }
  }
  .Image {
    width: 250px;
    height: 300px;
    opacity: 0; 
    transform: translateY(-20px);
    animation: fadeInFromTop 1s ease-out forwards; 
  }
  
  
  .Image:nth-child(1) {
    animation-delay: 0s; 
  }
  
  .Image:nth-child(2) {
    animation-delay: 1s; 
  }
  
  .Image:nth-child(3) {
    animation-delay: 2s; 
  }
  .Image:nth-child(4) {
    animation-delay: 3s; 
  }

  .Image:hover {
    border-top: 2px solid black; 
  }

  .SixPack {
    margin-bottom: 40px;
  }


  @media (max-width:768px) {

    .Container{
      margin-top: 0;
    }
    .Container h1{
     font-size: 27px;
    }

    .cardcontainer {
    gap: 10px;
      padding: 0px;
      margin-top: 20px;
      padding-bottom: 40px;
    
    }
  }