
.drawerContainer {
    background: #fafafa; 
    color: #343638;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    font-family: "Roboto", sans-serif;
  }
  
  .menuListContainer {
    padding: 0;
    margin: 0;
  }
  
  .parentList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .parentListOption {
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(52, 54, 56, 0.2);
    padding-bottom: 10px;
  }
  
  /* Menu Header */
  .menuHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #343638;
    transition: color 0.3s ease;
    padding: 10px 5px;
  }
  
  .menuHeader:hover {
    background-color: #fdf4d1; /* Slight yellowish hover effect */
  color: #000; 
  }
  
  /* Active Header */
  .activeHeader {
    color: #000;
  }
  
  /* Icons */
  .icon {
    font-size: 16px;
    transition: transform 0.3s ease;
  }
  
  .activeHeader .icon {
    transform: rotate(180deg); 
  }
  
  /* Submenu */
  .subMenuContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.3s ease;
    opacity: 0;
    padding-left: 10px;
  }
  
  .showSubMenu {
    max-height: 300px; 
    opacity: 1;
  }
  
  .subMenuLink {
    text-decoration: none;
    color: #343638;
    font-size: 16px;
    margin: 8px 0;
    display: block;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .subMenuLink:hover {
    color: #fff;
    transform: translateX(5px); 
  }
  

  .AuthContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .loginSingupContainer,
  .cartAndLogoutContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
  
  .button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .loginbtn,
  .signupbtn,
  .logoutbtn {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #343638;
    color: white;
    border: none;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .loginbtn:hover,
  .signupbtn:hover,
  .logoutbtn:hover {
    background-color: #ffd700;
  }
  
  /* Cart and Wishlist */
  .cart {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 15px;
  }
  
  .cart a {
    font-size: 24px;
    color: #343638;
    transition: color 0.3s ease;
  }
  
  .cart a:hover {
    color: #ffd700;
  }
  
  .fas, .far {
    font-size: 24px;
  }
  
 
  