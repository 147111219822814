.MainContainer {
  padding-top: 100px;
}

.Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 16px;
  padding: 16px;
}
