.cardcontainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

.icon {
  width: 0px;
  height: 0px;
}

@media (max-width:768px) {
  .cardcontainer{
   gap: 15px;
   padding: 0;
   margin: 20px 0px;
  }
}
