.buttonClass {
  all: unset;
  background-color: var(--bo-button);
  color: white;
  border: 1px solid var(--bo-button);
  font-size: 20px;
  padding: 8px 20px;
  border-radius: 7px;
  /* padding-left: 30px;
  padding-right: 30px; */
  cursor: pointer;
  font-weight: 400;
  transition: 0.3s ease-in-out;
  display: flex;
  gap:5px;
}

.buttonClass:hover {
  background-color: var(--bo-light);
  color: var(--bo-button);
  transition: 0.3s ease-in-out;
}

@media (max-width:440px) {
  .buttonClass{
    font-size: 14px;
  }
  

}
