.Container {
  padding: 20px;
}

.Coupon {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.couponbtn {
  border-radius: 10px;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: var(--bo-font-size-small);
}

.Error {
  position: relative;
  top: -10px;
  font-size: var(--bo-font-size-small);
  color: red;
}
