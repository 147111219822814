.card {
  /* background-color: var(--bo-light); */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../../assets/img/Background\ \(2\).png");
  background-size:cover;
  background-repeat: no-repeat;
  padding: 25px;
  width: 200px;
  height: 200px;
  margin: 10px;
  cursor: pointer;
  transition: opacity 0.6s ease, transform 0.6s ease;
  opacity: 0;
  transform: translateY(-20px);
}

.card.visible {
  opacity: 1;
  transform: translateY(0);
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 3px; /* Adjust height as needed */
  background-color: black;
  transition: width 0.3s ease, left 0.3s ease;
  transform: translateX(-50%); /* Center the pseudo-element */
}

.card:hover::before {
  width: 100%;
  left: 50%;
}

/* .card:hover {
  border-top: 3px solid black;
} */

.cardIcon {
  color: var(--bo-button);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background-color: #c7cad6;
  border-radius: 50%;
  font-size: 35px;
  margin-bottom: 10px;
}
.cardImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.cardTitle {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.card:nth-child(1) { transition-delay: 0s; }
.card:nth-child(2) { transition-delay: 0.2s; }
.card:nth-child(3) { transition-delay: 0.4s; }
.card:nth-child(4) { transition-delay: 0.6s; }
.card:nth-child(5) { transition-delay: 0.8s; }
.card:nth-child(6) { transition-delay: 1s; }


@media (max-width:440px) {
  .card{
    padding: 25px;
    width: 180px;
    height: 200px;
    margin: 0px;
  }
}
@media (max-width:385px) {
  .card{
    align-items: flex-start;
    justify-content: flex-start !important;
    padding: 25px;
    width: 170px;
    height: 210px;
    margin: 0px;
  }
}
