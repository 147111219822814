.MainContainer {
  width: 100%;
  display: flex;
  padding-top: 100px;
}

.Container {
  height: 700px;
  overflow-y: scroll;
  width: 70%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 16px;
  padding: 16px;
}
