.bannerContainer {
  text-align: center;
  padding-top: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  width: 90%;
  margin-left: 100px;
  margin-right: 100%;
}

.afterBannerContent {
  margin: 0px 100px;

}

.LeftCont {
  width: 60%;
  text-align: start;
}

.Container h1 {
  color: var(--bo-black);
  font-weight: 700;
  font-size: var(--bo-heading-h1-4);
}


.Container h4 {
  text-align: left;
  font-size: var(--bo-heading-h3-1);
}

.Section {
  text-align: left;
}

.Section h1 {
  font-size: var(--bo-heading-h3-1);
}