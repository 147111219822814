.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid black;
  padding: 10px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 50px;
  left: 50px;
  height: 100px;
  z-index: 1000;
}

.logoContainer img {
  width: 150px;
}

.menuListContainer {
  flex: 1;
}

.parentList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.parentListOption {
  position: relative;
}

.parentListOption>a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.parentListOption>a:hover {
  color: #0073e6;
}

.parentListOption:hover .subMenu {
  display: block;
}

.subMenu {
  display: none;
  position: absolute;
  font-size: 15px;
  left: -30px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 150px;
  z-index: 3;
}

.subLink:hover .subMenuList_list {
  display: block;
  position: relative;
  background-color: white;
  top: 0px;
  right: 50px;
  border-radius: 5px;
  right: 10px;
  padding: 10px 10px;
}

.subLink {
  padding: 3px 10px;
}

.subLink>a {
  text-decoration: none;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subLink>a:hover {
  background-color: #f9f9f9;
  color: #0073e6;
}

.subMenuList_list {
  display: none;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.searchContainer i {
  cursor: pointer;
}

.searchInput {
  position: absolute;
  top: 23px;
  right: 0;
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 6px;
}

.cart {
  width: 100px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
}

.drawerOpenerButton {
  display: none;
}


.loginSingupContainer{
  display: flex;
  gap: 10px;
}

@media (max-width:760px) {
  .menuListContainer {
    display: none;
  }



  .navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100vw;
    left: 0px;
    padding: 10px;
    height: 80px;
    z-index: 1000;
  }

  .loginSingupContainer {
    display: none;
  }
  
  .logoContainer img {
    width: 90px;
  }
  .drawerOpenerButton {
    background: none; 
    border: none; 
    cursor: pointer;
    padding: 8px; 
    border-radius: 5px;
    display: flex; 
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .drawerOpenerButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .drawerOpenerIcon {
    font-size: 24px; 
    color: #333; 
    transition: color 0.3s ease; 
  }
  
  .drawerOpenerButton:hover .drawerOpenerIcon {
    color: #0073e6; 
  }
  
  .drawerButtonCotainer{
    display: flex;
    align-items: center;
  }
  .signupbtn{
    padding: 5px 8px;
    font-size: 13px;
  }
}

@media (min-width: 1270px) and (max-width: 1750px) {

.menuListContainer{
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.parentList{
  width: 100%;
}
  .navbarContainer {
    height: 70px;
    right: 0px;
    left: 0px;
   }

  .logoContainer img {
    width: 90px;
  }

  .loginSingupContainer{
   align-items: center;
  }

  .loginbtn{
    padding: 5px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }

  .signupbtn{
    padding: 5px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }

  .parentListOption {
    position: relative;
  }
  
  .parentListOption>a {
    text-decoration: none;
    color: #333;
    font-size: 13px;
    font-weight: 700;
  }
  
  .parentListOption>a:hover {
    color: #0073e6;
  }
  
  .parentListOption:hover .subMenu {
    display: block;
  }
}