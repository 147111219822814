.Container {
  text-align: center;
  padding-top: 150px;
  display: grid;
  place-items: center;
}

.image {
  height: 250px;
  width: 250px;
}

.Courses {
  font-size: var(--bo-heading-h2-1);
}
