.container {
  width: 35%;
  display: flex;
  margin: auto;
  height: 21rem;
  padding: 30px;
  flex-direction: column;
  justify-content: center;

  margin-top: 120px;

  /* gap: 20px; */
}

.loginForm {
  box-shadow: none;
  padding: unset;
  width: auto;
}

.footer {
  text-align: left;
}

.loginButton {
}

.buttonContainer {
  position: relative;
  text-align: left;
}

.buttonContainer button {
  border-radius: 5px;
  font-size: var(--bo-font-size-medium-2);
  padding: 3px 14px;
  box-shadow: none !important;
}

.body {
}

.bottomActionLabel {
  font-size: var(--bo-font-size-regular-1);
}

.groupActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
