.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Container h1 {
  color: var(--bo-dark);
  font-size: var(--bo-heading-h1-5);
  font-weight: 700;
}

@media (max-width:768px) {
  .Container h1{
    font-size: 25px;
  }
}
